body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  padding: 2em;
  margin-left: 10%;
  margin-right: 10%;
  min-height: 100%;
}

body {
  background: #e2dede;
}

.app-logo {
  display: flex;
  align-items: center;  
  font-size: 0.95em !important;
}

.img-logo {
  height: 25px;
  margin-right: 0.6em;
}