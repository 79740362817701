.project__upload {
    margin-top: 1em;
}

.project__actions {
    justify-content: flex-end;
}

.MuiCardContent-root {
    min-height: 300px;
}
.project__upload { 
    height: 100%;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}